<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, useTemplateRef } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icon, Icons } from "../../icons";
import { Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxBottomBar from "../WuxBottomBar/WuxBottomBar.vue";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { DialogType, WuxDialogProps } from "./WuxDialog.core";

const props = defineProps<WuxDialogProps>();

const emit = defineEmits<{
    /**
     * Event without data.
     */
    (e: "dismiss"): void;
    (e: "unmounted"): void;
}>();

const slots = defineSlots<{
    default(): void;
}>();
const dialog = useTemplateRef("dialog");
const primaryButton = useTemplateRef("primaryButton");
const previouslyFocusedElement = ref<Element | null>();

onMounted(() => {
    previouslyFocusedElement.value = document.activeElement;
    dialog.value?.showModal();
    if (!slots.default) {
        primaryButton.value?.$el.focus();
    }
});
onUnmounted(() => {
    if (previouslyFocusedElement.value instanceof HTMLElement && document.contains(previouslyFocusedElement.value))
        previouslyFocusedElement.value.focus();
    emit("unmounted");
});

const dismiss = () => {
    if (!props.isHidden && props.isDismissible) emit("dismiss");
};

const onOutsideClick = (e: MouseEvent) => {
    // since the dialog itself has zero padding only clicks on the backdrop will be detected as direct dialog clicks
    if (e.target === dialog.value) dismiss();
};

const onEscape = (e: Event) => {
    // we need to prevent the default, because the dialog would immediately close on ESCAPE
    e.preventDefault();
    dismiss();
};

const statusClass = computed(() => (props.type ? `wux-dialog--${props.type.toLowerCase()}` : ""));
const icon = computed<{ src: Icon; color?: string } | undefined>(() => {
    switch (props.type) {
        case DialogType.SUCCESS:
            return { src: Icons.circle_check, color: "var(--wawi-color-green-500)" };
        case DialogType.CONFIRM:
            return { src: Icons.triangle_warning, color: "var(--wawi-color-orange-500)" };
        case DialogType.INFO:
            return { src: Icons.circle_information, color: "var(--wawi-color-primary-500)" };
        case DialogType.ERROR:
            return { src: Icons.circle_x, color: "var(--wawi-color-red-500)" };
        default:
            return undefined;
    }
});

// Override the keyboard escape
const onKeydown = (event: KeyboardEvent) => {
    if (ShortcutUtil.matches(Shortcuts.Escape, event)) {
        onEscape(event);
    }
};

const { m, optM } = useMsgFormatter();

const close = () => dialog.value?.close();
defineExpose({ close });
</script>

<template>
    <dialog
        ref="dialog"
        @keydown="onKeydown"
        @click="onOutsideClick"
        @cancel="onEscape"
        class="wux-dialog"
        :class="{
            'hidden-print': true,
            'wux-dialog--hidden': props.isHidden,
            [statusClass]: statusClass,
        }"
    >
        <div>
            <div class="wux-dialog__header" v-if="props.headerMsg">
                <WuxIcon :src="icon.src" :size="48" :color="icon.color" v-if="icon" />
                <h1>{{ m(props.headerMsg) }}</h1>
            </div>
            <div class="wux-dialog__body">
                {{ optM(props.contentMsg) }}
                <component :is="props.content.component" v-if="props.content" />
                <slot />
                <small v-if="props.footNoteMsg" class="wux-dialog__footnote">{{ m(props.footNoteMsg) }}</small>
            </div>
            <WuxBottomBar isComponentLevel class="wux-dialog__footer">
                <template v-if="props.secondary">
                    <WuxButton
                        v-for="(a, i) in props.secondary"
                        variant="text"
                        :key="i"
                        :isDisabled="a.isDisabled"
                        :isLoading="a.isLoading"
                        :labelMsg="a.labelMsg"
                        @click="a.onClick"
                    />
                </template>
                <WuxButton
                    ref="primaryButton"
                    isPrimary
                    variant="text"
                    :isDisabled="props.primary.isDisabled"
                    :isLoading="props.primary.isLoading"
                    :labelMsg="props.primary.labelMsg"
                    @click="props.primary.onClick"
                />
            </WuxBottomBar>
        </div>
    </dialog>
</template>

<style lang="scss">
.wux-dialog {
    $wux-dialog-backdrop-color: rgba(0, 0, 0, 0.6);

    border: none;
    padding: 0;
    outline: 0;

    &[open]::backdrop {
        animation: backdrop-fade-in 250ms ease forwards;
    }

    &--hidden {
        visibility: hidden;

        &[open]::backdrop {
            // Hint: Cannot use "backwards" nor "animation-direction: reverse" as this seems to not work with transparent color
            animation: backdrop-fade-out 250ms ease forwards;
        }
    }

    &--error {
        width: 35rem;
    }

    @keyframes backdrop-fade-in {
        from {
            background: transparent;
        }

        to {
            background: $wux-dialog-backdrop-color;
        }
    }

    @keyframes backdrop-fade-out {
        from {
            background: $wux-dialog-backdrop-color;
        }

        to {
            background: transparent;
        }
    }

    &__header {
        padding: 1rem 1.3rem 0 1rem;
        display: flex;
        align-items: center;
        gap: 1em;

        h1 {
            margin: 0;
        }
    }

    &__body {
        padding: 1rem 1.5rem;
        white-space: pre-line;
        // to forward the height of the body container and allow to control overflow behavior inside custom dialogs
        height: 100%;
    }

    &__footnote {
        display: block;
        margin-top: 1rem;
    }
}
</style>
