import { CSSProperties, reactive, watch } from "vue";
import { EventHandler } from "../../types";

export type PopoverAlignment = "left" | "right" | "center";
export type PopoverPositionProps = "top" | "bottom" | "preferTop" | "preferBottom" | "auto";
// lazyTop and lazyBotton should only be internal
export type PopoverPosition = PopoverPositionProps | "lazyTop" | "lazyBottom";
export type PopoverOpenMechanism = "hover" | "click" | "manual";

export type PopoverTargetProps = {
    openMechanism?: PopoverOpenMechanism;
    alignment?: PopoverAlignment;
};

/**
 * layoutObserverMap tracks which elements need to be observed for viewport changes
 */
export const layoutObserverMap = reactive(new Map<HTMLElement, () => void>());
const handleScroll: EventHandler<Event> = (event: Event) => {
    if (event.target === document || event.target === window) {
        return layoutObserverMap.forEach((handler) => handler());
    }
    layoutObserverMap.forEach((handler, element) => (event.target as Element).contains?.(element) && handler());
};

watch(layoutObserverMap, () => {
    if (layoutObserverMap.size) {
        // Observe the scrollend event so that we can update popover positions
        // we could use "scroll" event here to, but I am worried about performance issues
        // "capture" is used here because scroll events don't bubble
        document.addEventListener("scrollend", handleScroll, { capture: true });
        window.addEventListener("resize", handleScroll, { capture: true });
    } else {
        document.removeEventListener("scrollend", handleScroll, { capture: true });
        window.removeEventListener("resize", handleScroll, { capture: true });
    }
});

type PopoverCoords = Pick<CSSProperties, "left" | "top">;
export type PopoverOpeningDirection = "top" | "bottom";
type PopoverSettings = { position: PopoverPosition; coords: PopoverCoords; openingDirection: PopoverOpeningDirection };
/**
 * Calculates the position of the popover in the [top layer](https://developer.mozilla.org/en-US/docs/Glossary/Top_layer).
 * The absolute position in the top layer is relative to the ViewPort, so we need to account for the scroll positions.
 */
export const calculatePopoverCoords = (
    alignment: PopoverAlignment,
    targetRect: DOMRectReadOnly,
    popoverRect: DOMRectReadOnly,
    offset?: number,
    position?: PopoverPosition,
): PopoverSettings => {
    const { scrollTop, clientHeight, scrollLeft } = document.documentElement;
    const {
        left: leftTarget,
        right: rightTarget,
        top: topTarget,
        bottom: bottomTarget,
        width: targetWidth,
    } = targetRect;
    const { height, width: popoverWidth } = popoverRect;

    // Used to compute the left padding we have in case the need to center the Popover
    const leftPaddingIfCentered = popoverWidth > targetWidth ? 0 : (targetWidth - popoverWidth) / 2;

    const left =
        (alignment !== "right"
            ? leftTarget + (alignment === "center" ? leftPaddingIfCentered : 0)
            : rightTarget - popoverWidth) + scrollLeft;

    const defaultOffset = offset ?? 0;
    const topAbove = topTarget - height + scrollTop - defaultOffset;
    const remainingAbove = topTarget - height;
    const topBeneath = bottomTarget + scrollTop + defaultOffset;
    const remainingBeneath = clientHeight - bottomTarget - height;
    let top = remainingBeneath < 0 && remainingAbove > remainingBeneath ? topAbove : topBeneath; // this is the default `auto` case, that works exactly as the `lazyBottom` case
    if (position === "lazyTop") top = remainingAbove < 0 && remainingAbove < remainingBeneath ? topBeneath : topAbove;
    else if (position === "preferTop")
        top = remainingAbove >= 0 || remainingAbove >= remainingBeneath ? topAbove : topBeneath;
    else if (position === "preferBottom")
        top = remainingBeneath >= 0 || remainingBeneath >= remainingAbove ? topBeneath : topAbove;
    else if (position === "top") top = topAbove;
    else if (position === "bottom") top = topBeneath;
    let actualPosition = position ?? "auto"; // this is correct for preferTop/preferBottom/top/bottom, since we don't want to change the preference
    if (!position || position === "auto" || position === "lazyTop" || position === "lazyBottom")
        actualPosition = top === topAbove ? "lazyTop" : "lazyBottom";

    return {
        position: actualPosition,
        coords: Object.fromEntries(Object.entries({ left, top }).map(([k, v]) => [k, `${v}px`])),
        openingDirection: top === topAbove ? "top" : "bottom",
    };
};
