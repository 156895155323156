// This file is duplicated in ui/libs.
// This version here should NOT be exported outside ui/components
const NON_PROD_STAGES = ["dev", "tst", "qas"] as const;
type LowercaseStage = (typeof NON_PROD_STAGES)[number];
type Stage = Uppercase<LowercaseStage>;

export const _getCurrentStage = (): Stage | undefined => {
    const hostParts = location.hostname.split(".");
    const firstPart = hostParts[0] as LowercaseStage;
    if (hostParts.length !== 4 || !NON_PROD_STAGES.includes(firstPart)) {
        return undefined;
    }
    return firstPart.toUpperCase() as Stage;
};

const isLocal = () => import.meta.env.DEV;

/** Only triggers the function if the application is running on the matching
 * stage. Special case: Specifying 'DEV' will also include localhost
 */
export const doOnlyOnStage = (stage: Stage, fn: () => void) => {
    const isMatchingStage = stage === _getCurrentStage() || (stage === "DEV" && isLocal());
    if (isMatchingStage) fn();
};
