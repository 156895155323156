<script lang="ts" setup>
import { computed, nextTick, ref, useTemplateRef, watchEffect } from "vue";
import { Icon, Icons } from "../../icons";
import { ComponentUtils } from "../../utils";
import { vOnOutsideClick } from "../../utils/vOnOutsideClick";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import WuxPopover from "../WuxPopover/WuxPopover.vue";
import WuxTreeList from "../WuxTreeList/WuxTreeList.vue";
import { OptionButtonProps } from "./WuxOptionButton.core";

const OPTION_BUTTON_CLOSING_DELAY = 50;

const optionButtonRef = useTemplateRef("optionButtonRef");
const buttonRef = useTemplateRef("buttonRef");
const treeListRef = useTemplateRef("treeListRef");

const props = defineProps<OptionButtonProps>();

const emit = defineEmits<{ open: [] }>();

const isOptionsVisible = ref(false);
watchEffect(async () => {
    if (!isOptionsVisible.value) return;
    emit("open");

    adjustFlyoutAlignment();
    await nextTick(); // Wait for options to be displayed after setting isOptionsVisible to true
    treeListRef.value?.focus({ preventScroll: true }); // Scrolling needs to be prevented as the tree list is positioned absolutely inside the popover
});

// if neither icon nor label is provided, the icon with three dots (…) is used.
const optionButtonIcon = computed<Icon | undefined>(() =>
    !props.icon && !props.labelMsg && !props.label ? Icons["more_horizontal"] : props.icon,
);

const flyoutAlignLeft = ref(false);

// An addition to automatically align the flyout to the left or right side of the button
const adjustFlyoutAlignment = () => {
    const element = optionButtonRef.value;
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const distanceToRight = viewportWidth - rect.right;
    flyoutAlignLeft.value = distanceToRight >= window.innerWidth / 2;
};

const handleShowOptions = async () => {
    isOptionsVisible.value = !isOptionsVisible.value;
};

const handleSelectItem = async () => {
    if (props.keepOpenAfterSelect) return;
    isOptionsVisible.value = !isOptionsVisible.value;
};

const handleOutsideClick = () => {
    if (!isOptionsVisible.value) return;
    handleShowOptions();
};

const close = async () => {
    buttonRef.value?.focus();
    await ComponentUtils.sleep(OPTION_BUTTON_CLOSING_DELAY); // to make the popover close smoother
    isOptionsVisible.value = false;
};

defineExpose({ close });
</script>

<template>
    <div
        ref="optionButtonRef"
        v-on-outside-click="handleOutsideClick"
        class="wux-option-button"
        :class="{
            'wux-option-button--primary': props.isPrimary,
            'wux-option-button--dense': props.isDense,
        }"
        @keydown.tab.stop="close"
    >
        <!-- The `wux-option-button__button--open` class is used in the Table keyboard navigation -->
        <WuxButton
            ref="buttonRef"
            class="wux-option-button__button"
            :class="{ 'wux-option-button__button--open': isOptionsVisible }"
            :variant="props.variant"
            type="button"
            :tabindex="isOptionsVisible ? '-1' : undefined"
            :secondary="!props.isPrimary"
            :icon="optionButtonIcon"
            :label="props.label"
            :labelMsg="props.labelMsg"
            :isDisabled="!props.options || props.isDisabled"
            :isLoading="props.isLoading"
            :titleMsg="props.titleMsg"
            :isPrimary="props.isPrimary"
            :isDense="props.isDense"
            :shortcut="props.shortcut"
            @click="handleShowOptions"
        >
            <template #iconRight v-if="props.labelMsg || props.label">
                <WuxIcon
                    class="wux-option-button__chevron"
                    :class="{ 'wux-option-button__chevron--open': isOptionsVisible }"
                    :src="props.isDense ? Icons.chevron_down_small : Icons.chevron_down"
                    :size="24"
                    :titleMsg="props.titleMsg"
                />
            </template>
        </WuxButton>
        <WuxPopover
            openMechanism="manual"
            class="wux-option-button__popover"
            :isOpen="isOptionsVisible"
            :alignment="flyoutAlignLeft ? 'left' : 'right'"
            :offset="8"
        >
            <WuxTreeList
                v-if="props.options"
                class="wux-option-button__tree-list"
                ref="treeListRef"
                hasShortcutsEnabled
                :items="props.options"
                @select="handleSelectItem"
                @keydown.space="close"
                @keydown.escape="close"
            />
        </WuxPopover>
    </div>
</template>

<style lang="scss">
.wux-option-button {
    display: inline-block;

    &--dense {
        .wux-tree-list-item {
            padding: 0.25rem 0.5rem;
        }
    }

    &__chevron {
        transition: transform 0.25s;

        &--open {
            transform: rotate(180deg);
        }
    }

    &:focus-within {
        .wux-option-button__button {
            outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-primary-300);
        }
        .wux-tree-list-item {
            outline: none;
        }
    }
}

.wux-option-button__popover {
    background-color: var(--wawi-color-grayscale-white);
    border: 1px solid var(--wawi-color-primary-500);
    border-radius: 4px;
    max-height: 15.125rem; // The height of six items
}
</style>
