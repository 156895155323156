export const FetchAbortReasons = {
    TIMEOUT: "TIMED OUT",
    OFFLINE: "OFFLINE",
    BROWSER_UNLOAD: "BROWSER_UNLOAD",
};

/**
 *
 * This code is used inside the application to handle the scenario where a user is redirected
 * to a new URL or closes the browser, which might cause ongoing fetch requests to fail.
 * To prevent the "failed to fetch" errors, we abort all ongoing requests before redirecting / closing.
 *
 * Consumers of this abort controller can detect if a fetch was aborted in this manner
 * by checking the reason provided when the request is aborted.
 *
 **/
const globalAbortController = new AbortController();
/** Should only be used in `ui/libs/auth` */
export const globalAbortSignal = globalAbortController.signal;
/** Should only be used in `ui/libs/auth` and `ui/libs/skeleton` */
export const abortAllRequests = () => globalAbortController.abort(FetchAbortReasons.BROWSER_UNLOAD);

export const _getTimeoutController = (
    timeLimit: number,
    signal?: AbortSignal,
): { signal: AbortSignal; clearTimeout: () => void } => {
    // early escape (if already aborted, just forward that signal)
    if (signal?.aborted) return { signal, clearTimeout: () => undefined };

    // Bundle in the globalAbortSignal to ensure that all requests can be canceled on browser unload
    const ctrl = makeAbortController(globalAbortSignal, signal);

    const timeout = setTimeout(() => ctrl.abort(FetchAbortReasons.TIMEOUT), timeLimit);
    return { signal: ctrl.signal, clearTimeout: () => clearTimeout(timeout) };
};

/** Creates a new `AbortController` which is optionally "subscribed" to a list of `AbortSignals` and will be canceled
 * automatically whenever one of the passed signals is canceled
 *
 * Cancelling the returned AbortController directly has no effect on the passed signals.
 * This means that `makeAbortController()` is equivalent to `new AbortController()`.
 * Similarly, `makeAbortController(controller.signal)` makes a "local copy" of some existing `controller` (which might be
 * shared by multiple requests)
 */
export const makeAbortController = (...signals: (AbortSignal | undefined)[]) => {
    const ctrl = new AbortController();

    // related:
    // - https://github.com/whatwg/fetch/issues/905
    // - https://github.com/whatwg/dom/issues/920#issuecomment-726203604
    signals.forEach((signal) => {
        if (!signal) return;
        signal.addEventListener("abort", () => ctrl.abort(signal.reason), {
            signal: ctrl.signal,
            once: true,
        });
    });

    return ctrl;
};
