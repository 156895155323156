import { computed, ComputedRef, Ref, ref, toRef } from "vue";
import { diff } from "deep-object-diff";
import { z } from "zod";
import { MessageOrId } from "../i18n";

const DEFAULT_SLEEP_TIME = 1000;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const equals = (a: any, b: any): boolean => {
    if (a === b) return true;
    // the diff tool doesn't work correctly with null / undefined
    // https://github.com/mattphillips/deep-object-diff/issues/29#issuecomment-369334388
    // eslint-disable-next-line eqeqeq
    if (a == null || b == null) return false;
    // for dates it's easier to compare them directly then using the diff tool
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    // the diff tool doesn't work correctly with two primitives
    if (a !== Object(a) && b !== Object(b)) return false;
    return Object.keys(diff(a, b)).length === 0;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const includes = (array?: any[], item?: any) => {
    if (!array || item === undefined) {
        return false;
    }
    for (const element of array) {
        if (equals(element, item)) {
            return true;
        }
    }
    return false;
};

const stringToJSONSchema = () =>
    z.string().transform((str, ctx) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: "Invalid JSON" });
            return z.NEVER;
        }
    });

// polyfill for Promise.withResolvers()
const promiseWithResolvers = <T>(): PromiseWithResolvers<T> => {
    let resolve: ((value: T | PromiseLike<T>) => void) | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: ((reason?: any) => void) | undefined;
    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        // the callback always assigns these values
        resolve: resolve!,
        reject: reject!,
    };
};

const promiseWithTimeout = async <T>(promise: Promise<T>, time: number): Promise<T> => {
    let timer: NodeJS.Timeout;
    return Promise.race<T>([promise, new Promise((_r, rej) => (timer = setTimeout(rej, time)))]).finally(() =>
        clearTimeout(timer),
    );
};

/**
 * Debounce the call to a function: It will be called after the wait time when it was not called again in this time.
 * @param func function that should be debounced
 * @param wait maximum wait time in ms
 * @returns an async function which will only call `func` after the wait time has elapsed.
 * Each call to the debounced function will reset the waiting period
 */
const debounce = <TArgs extends unknown[], TResult>(
    func: (...args: TArgs) => Promise<TResult> | TResult,
    wait: number,
) => {
    let resolvers: ((result: Promise<TResult> | TResult) => void)[] = [];
    const resolveAll = (result: Promise<TResult> | TResult) => {
        resolvers.forEach((resolve) => resolve(result));
        resolvers = []; // Reset for future calls
    };
    let timeout: number;
    return (...args: TArgs) => {
        clearTimeout(timeout); // i.e. reset the waiting period each time the inner function is called
        const { promise, resolve } = promiseWithResolvers<TResult>();
        resolvers.push(resolve);
        timeout = window.setTimeout(() => resolveAll(func(...args)), wait); // Once waiting period complete, resolve all previous calls
        return promise;
    };
};

const copyToClipboard = (content: string): Promise<void> =>
    navigator.clipboard
        .write([
            new ClipboardItem({
                "text/plain": new Blob([content], { type: "text/plain" }),
            }),
        ])
        .catch(() => undefined);

const isMacOS = (): boolean => navigator.userAgent.includes("Mac OS X");

/**
 * This utility is used to detect mobile device specifically targeting Android and certain tablets like Zebra devices (E40, E45).
 */
const isMobile = (): boolean => {
    const hasTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const isSmallScreen = window.screen.width <= 1282;
    // Based on 1280x800 screen size of the current current Zebra devices (e.g. E40, E45)

    return isSmallScreen && hasTouchScreen;
};

/** This function generates a prefixed action name for a given "ddName" (data dictionary name) or "MessageOrId" object.
 * The prefix "dd.action-name" is significant as it indicates actions already configured by the teams.
 * If no "ddName" is provided, it returns an empty string.
 * Note: We send the translation key to DataDog, not the translated message, to maintain data privacy and consistency across multiple locales.
 */
const getDDActionName = (ddName?: string | MessageOrId): string => {
    if (!ddName) return "";
    const name = typeof ddName === "string" ? ddName : ddName.id;
    return `dd.action-name.${name}`;
};

/**
 * This callback can be used to sleep for `ms` milliseconds. If no parameter is provided, it'll sleep for 1 second.
 */
const sleep = async (ms: number = DEFAULT_SLEEP_TIME): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

/** Returns a `ref` whose value is the value of the most recently updated passed ref.
 * This is particuarly useful when you want to use the latest data from two different sources.
 * (See `useSyncedRef` for an example.)
 *
 * In the case that multiple of the passed refs change at the same time, the new value of the rightmost changed ref will be returned.
 * For example, if ["A", "B"] changes to ["C", "D"], then the resulting value will change from "B" to "D".
 * Conceptually, this can be thought of as "A" changing to "C" immediately before "B" changes to "D"
 *
 * @param refs a non-empty list of `ref`s (or getter functions).
 * Initially, the returned `ref` has the value of the rightmost `ref` passed in
 */
const useLatest = <T>(...values: (Ref<T> | (() => T))[]): ComputedRef<T> => {
    const refs = values.map((value) => toRef(value));
    // We hold everything in objects like `{ value: T }` to handle the case that the `find` returns `{ value: undefined }`
    let lastValues = refs.map((ref) => ({ value: ref.value }));
    let lastResult = lastValues[lastValues.length - 1];
    return computed(() => {
        const nextValues = refs.map((ref) => ({ value: ref.value }));
        const nextResult =
            // Search from right to left
            nextValues.findLast((nextValue, index) => nextValue.value !== lastValues[index].value) ?? lastResult;
        lastValues = nextValues;
        lastResult = nextResult;
        return nextResult.value;
    });
};

/** Creates a ref which can contain "local" changes and whose value will be reset if `getter` updates
 *
 * It can be thought of as a `ref` with an automatic `watch` to reset if the outside value changes, i.e.
 * ```ts
 * const syncedRef = ref(getter());
 * watch(() => getter(), (value) => (syncedRef.value = value));
 * ```
 *
 * There are several situations where this is useful:
 * 1. You can convert component `props` to an editable `ref`:
 *     ```tsx
 *     const options = ComponentUtils.useSyncedRef(() => props.options);
 *
 *     const onAddOption = () => {
 *         options.value.concat('new option');
 *     }
 *
 *     <WuxButton :onClick="onAddOption" />
 *     <WuxDropdown :options="options" />
 *     ```
 * 2. It allows for you to contain "optimistic" values in the UI:
 *     ```tsx
 *     const optimisticIsProcessRunning = ComponentUtils.useSyncedRef(() => isProcessRunning());
 *
 *     const onClick = () => {
 *         triggerProcessInBackground(); // will eventually change result of `isProcessRunning`
 *         optimisticIsProcessRunning.value = true; // optimistically assume running
 *     }
 *
 *     <button :onClick="onClick">
 *       {{ optimisticIsProcessRunning ? 'Running' : 'Not running' }}
 *     </button>
 *     ```
 * 3. It can be used to hold form data which changes when the initial data changes
 *     ```tsx
 *     const formData = ComponentUtils.useSyncedRef(() => ({ ...initialData }));
 *
 *     <form ... >
 *         <WuxInput
 *           :labelMsg="__('form.user-name')"
 *           v-model="formData.name"
 *         />
 *         <WuxInput
 *           :labelMsg="__('form.user-email')"
 *           v-model="formData.email"
 *         />
 *         ...
 *     </form>
 *     ```
 */
const useSyncedRef = <T>(getter: () => T) => {
    const internalRef = ref(getter());
    // Important: we prefer the `internalRef` over the `getter` in the case where both change simultaneously
    const latest = useLatest(getter, internalRef);
    return computed<T>({
        get: () => latest.value,
        set: (value) => (internalRef.value = value),
    });
};

/**
 * Finds the next valid element in the given list of elements.
 *
 * @param elements - an array of elements
 * @param currentIndex - the current index
 * @param direction - the direction to search in, either "forward" or "back"
 * @param predicate - a function that takes an element and returns a boolean indicating whether it is valid or not
 * @returns the index of the next valid element, or undefined if no valid element could be found
 */
const nextElement = <T>({
    elements,
    currentIndex,
    direction,
    predicate = () => true,
}: {
    elements: T[];
    currentIndex: number;
    direction: "forward" | "back";
    predicate?: (element: T) => boolean;
}): number | undefined => {
    const validElements = elements.filter((element) => predicate(element));
    if (validElements.length === 0) return undefined;

    const lastElementIndex = elements.length - 1;
    const isNext = direction === "forward";

    // try to focus next/prev element
    let newElementIndex = currentIndex >= 0 ? currentIndex + (isNext ? 1 : -1) : isNext ? 0 : lastElementIndex;

    // if that element is disabled, find next enabled one
    while ((isNext && newElementIndex <= lastElementIndex) || (!isNext && newElementIndex >= 0)) {
        if (elements[newElementIndex] && predicate(elements[newElementIndex])) break;
        newElementIndex += isNext ? 1 : -1;
    }

    // if we went out of bounds of the visible elements, wrap around to the next enabled element
    if ((isNext && newElementIndex > lastElementIndex) || (!isNext && newElementIndex < 0)) {
        newElementIndex = isNext ? 0 : lastElementIndex;
        while (elements[newElementIndex] && !predicate(elements[newElementIndex])) {
            newElementIndex += isNext ? 1 : -1;
        }
    }
    return newElementIndex;
};

/** Breaks an array into chunks or batches of size `size`
 *
 * @param array an array of any type
 * @param size number of elements in each chunk / batch. If a number smaller than
 * 1 is passed, will be treated as a batch size of 1
 * @returns an array of arrays (the batches / chunks)
 */
const chunkArray = <T>(array: T[], size: number) => {
    if (size <= 1) return array.map((elem) => [elem]);
    const chunks: T[][] = [];
    for (let index = 0; index < array.length; index += size) {
        chunks.push(array.slice(index, index + size));
    }
    return chunks;
};

export const ComponentUtils = {
    equals,
    includes,
    stringToJSONSchema,
    promiseWithResolvers,
    promiseWithTimeout,
    debounce,
    copyToClipboard,
    isMacOS,
    isMobile,
    sleep,
    useLatest,
    useSyncedRef,
    nextElement,
    chunkArray,
};

export const DatadogActions = {
    getName: getDDActionName,
};
