import { RouteLocationNormalized } from "vue-router";
import { abortAllRequests, APIError, FetchAbortReasons, globalAbortSignal } from "@ui/clients";
import { MessageId } from "@ui/components";
import { CurrentModule } from "../module/current";
import { DatadogActions } from "../skeleton";
import { Dialog } from "../skeleton/Dialog";
import { LOG } from "../skeleton/errors";
import { LOGIN_ERROR_PATH, LOGIN_PATH, LOGIN_SUCCESS_PATH, LOGOUT_PATH, LOGOUT_SUCCESS_PATH } from "./paths";
import { AuthService } from "./services/AuthService";
import { removeUserToken, setUserTokenAndExpirationDate, useUserStore } from "./user";

const WAM_LOGIN_APPLICATION_ID: Map<string, string> = new Map<string, string>([
    ["https://dev.pt.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.mt.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.it.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.rs.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.st.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.us.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.yc.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.yi.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.yd.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.yp.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.int.wawi.lidl", "b8af25da-6620-40ec-8781-d47f1df5145c"],
    ["https://dev.global.wawi.lidl", "5e9311e0-8f6a-4acb-a5c2-b79959ad81db"],
    ["https://tst.es.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.pt.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.mt.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.be.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.de.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.it.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.fg.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.rs.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.st.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.us.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.yc.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.yi.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.int.wawi.lidl", "ef9e4745-115e-4473-a090-ef0785ccec16"],
    ["https://tst.global.wawi.lidl", "d042ca6f-800b-4213-b589-1cca960d0e30"],
    ["https://qas.at.wawi.lidl", "a5689419-791e-435d-9e08-eafba2b2cd94"],
    ["https://qas.be.wawi.lidl", "ec30a057-a96a-4359-ab44-637e5d97816e"],
    ["https://qas.bg.wawi.lidl", "8ff947b1-5b6c-4f76-8834-493923d6dbe3"],
    ["https://qas.ch.wawi.lidl", "470d5d71-6887-4a6f-aa7b-45e02c051777"],
    ["https://qas.cs.wawi.lidl", "2cecdb27-53da-4da4-96e5-c80929a95412"], // CS has the same appId as ES
    ["https://qas.cy.wawi.lidl", "3c7e4616-042e-4744-8cef-b143c70e6a26"],
    ["https://qas.cz.wawi.lidl", "4a8ac553-dc74-48fb-946e-90a9d10df8e9"],
    ["https://qas.de.wawi.lidl", "28fbbc37-6bf8-4c3e-9f43-cf5989727fa6"],
    ["https://qas.dk.wawi.lidl", "843ff652-480e-4b47-af8d-7c14b6bac3cb"],
    ["https://qas.ee.wawi.lidl", "86a79c5e-cf8b-4321-b8b0-fc2b3370f9f6"],
    ["https://qas.es.wawi.lidl", "2cecdb27-53da-4da4-96e5-c80929a95412"],
    ["https://qas.fi.wawi.lidl", "8220b8d8-7f66-4023-a24b-1ca8f47a036f"],
    ["https://qas.fr.wawi.lidl", "17861555-05c0-4091-9986-83d2c3afb753"],
    ["https://qas.gb.wawi.lidl", "3f6c68ce-e773-4502-a81a-ad4238b8664b"],
    ["https://qas.gr.wawi.lidl", "cb373ac3-c784-402c-8895-f60b734d7fb6"],
    ["https://qas.hr.wawi.lidl", "d3108306-f501-4161-9da7-7e5b239e00da"],
    ["https://qas.hu.wawi.lidl", "3f0cab7b-550b-47c6-b170-05051fb4ab14"],
    ["https://qas.ie.wawi.lidl", "a37f5a8a-8ea3-4b90-9e35-ecfd8a6f645e"],
    ["https://qas.it.wawi.lidl", "8c0d9216-462e-44e2-8524-a2390ca0f412"],
    ["https://qas.yi.wawi.lidl", "8c0d9216-462e-44e2-8524-a2390ca0f412"], // same cluster as italy
    ["https://qas.lt.wawi.lidl", "b4589c81-6711-4f02-b133-6f1119747507"],
    ["https://qas.lv.wawi.lidl", "84223e64-5cbc-42f8-807d-abbe6be196f8"],
    ["https://qas.mk.wawi.lidl", "7f1eeca3-ae03-4952-9ceb-04c771601118"],
    ["https://qas.mt.wawi.lidl", "d337b3fa-1918-4117-978b-ab1ac6911a8f"],
    ["https://qas.ni.wawi.lidl", "288ef426-0f91-4818-9327-e5468d2965a1"],
    ["https://qas.nl.wawi.lidl", "c1f242f5-91cd-42a1-a452-ca901b59efd2"],
    ["https://qas.pl.wawi.lidl", "962a2080-4351-4be4-bdd1-5690a4cb9358"],
    ["https://qas.pt.wawi.lidl", "b53627b9-ad5b-44d1-85bf-f4961a04388c"],
    ["https://qas.yp.wawi.lidl", "b53627b9-ad5b-44d1-85bf-f4961a04388c"],
    ["https://qas.ro.wawi.lidl", "c04096e8-7f1b-44b5-8bc0-611aa01a8d16"],
    ["https://qas.rs.wawi.lidl", "47d8ec80-bac1-4254-b91b-6e68b00f4418"],
    ["https://qas.se.wawi.lidl", "0f6dd898-bfb5-4bb0-8890-4be2134ae774"],
    ["https://qas.si.wawi.lidl", "e0f41a09-7da7-4d4b-ba66-6a49eca02a8b"],
    ["https://qas.sk.wawi.lidl", "273392d3-e7d0-452e-be37-3e10f1c6087e"],
    ["https://qas.us.wawi.lidl", "f4c21d9b-8e72-44ab-957c-3ddd8a360f9f"],
    ["https://qas.global.wawi.lidl", "87ee4e96-09c7-431e-80f5-d7dbe1aa21dc"],
    ["https://at.wawi.lidl", "5238f456-0381-4921-8902-23ad0f63bd51"],
    ["https://be.wawi.lidl", "e8edd06f-e07e-41a6-8197-158ba73ed703"],
    ["https://bg.wawi.lidl", "675ab676-674c-4b28-ba9e-110d36ecf769"],
    ["https://ch.wawi.lidl", "4af02945-88ce-4816-87b2-a245b25c9544"],
    ["https://cs.wawi.lidl", "2d83b75a-7255-4bb2-8685-6934d861b96b"], // CS has the same appId as ES
    ["https://cy.wawi.lidl", "577b682c-f271-43c5-bc07-a52ebd3d5cbe"],
    ["https://cz.wawi.lidl", "63739f1c-9e30-4d65-ad96-e279bc505880"],
    ["https://de.wawi.lidl", "0706edca-b8ac-41ac-8bad-a5a4ba08bc91"],
    ["https://dk.wawi.lidl", "11669628-d951-48ea-a2d3-1d968dd256ed"],
    ["https://ee.wawi.lidl", "6f063dc0-3a5e-40fb-920c-29ef758cc426"],
    ["https://es.wawi.lidl", "2d83b75a-7255-4bb2-8685-6934d861b96b"],
    ["https://fi.wawi.lidl", "45f77090-75da-4e6f-8a80-84ea2adb55bd"],
    ["https://fr.wawi.lidl", "2939ef47-b395-4177-817c-20f6373d87f3"],
    ["https://gb.wawi.lidl", "c52e4566-08a9-49b2-a8ba-805e9320d04f"],
    ["https://gr.wawi.lidl", "062616ee-4863-4d4f-91c5-60641bb547c1"],
    ["https://hr.wawi.lidl", "977c3a3c-e73c-4277-8623-065ce1c801d2"],
    ["https://hu.wawi.lidl", "035e6946-3e24-4562-ad19-ede51de1328c"],
    ["https://ie.wawi.lidl", "6d03dbc3-31a3-4bc8-b8f5-3f5c13851071"],
    ["https://it.wawi.lidl", "425806b2-e485-42a0-98d7-c8d4328e6af8"],
    ["https://lt.wawi.lidl", "421f0e44-44d1-4c8b-95e1-c74c5cac548c"],
    ["https://lv.wawi.lidl", "5c195584-cee0-4a8d-9e6d-1eee8ac3deeb"],
    ["https://mt.wawi.lidl", "685e2c0e-815c-4944-956a-48fa96affe77"],
    ["https://ni.wawi.lidl", "00f0ac97-805d-4200-9e23-cb7add99bab1"],
    ["https://nl.wawi.lidl", "eafc52f3-baa6-43ee-aafc-53edb6488950"],
    ["https://pl.wawi.lidl", "986b7e78-3271-43b2-b3c6-bf402135b44f"],
    ["https://pt.wawi.lidl", "3a506294-06ac-41e7-a2d2-3c63e99ba74b"],
    ["https://ro.wawi.lidl", "42137bbd-4400-4101-81d5-cb88dd49dece"],
    ["https://rs.wawi.lidl", "f49ec4b6-f7c3-400b-ae28-e20d9b7c69bb"],
    ["https://se.wawi.lidl", "7f9278f5-5b64-4a94-9518-c61ed4edd382"],
    ["https://si.wawi.lidl", "6939f5f2-b85a-4197-bb74-f8568d5cb883"],
    ["https://sk.wawi.lidl", "7e2f2866-e0d1-4867-8434-eb4728e35aea"],
    ["https://us.wawi.lidl", "7bcf53fe-813e-4a11-a070-43ef155f6165"],
    ["https://global.wawi.lidl", "95bb6447-dcfe-4c5a-9198-d9982d4fbf8c"],
]);
const AAD_LOGIN_URL = "https://login.microsoftonline.com/office.schwarz/oauth2/v2.0/authorize";
const AAD_LOGOUT_URL = "https://login.microsoftonline.com/office.schwarz/oauth2/v2.0/logout";

const REDIRECT_PATH_STORAGE_ENTRY = "pathBeforeLogin";

const getLoginApplicationId = (): string | undefined => {
    let aadApplicationId = WAM_LOGIN_APPLICATION_ID.get(window.origin);

    if (import.meta.env.DEV) {
        aadApplicationId =
            import.meta.env.BASE_URL === "/module/wsm/" ||
            import.meta.env.BASE_URL === "/module/fam/" ||
            import.meta.env.BASE_URL === "/module/sat/" ||
            import.meta.env.BASE_URL === "/module/iam/"
                ? "5e9311e0-8f6a-4acb-a5c2-b79959ad81db"
                : "b8af25da-6620-40ec-8781-d47f1df5145c";
    }

    return aadApplicationId;
};

export const getCurrentModuleScope = () => {
    if (location.pathname.startsWith("/module/launchpad") || location.pathname.startsWith("/login-success")) {
        return "LNP";
    }

    return CurrentModule.get()?.name || "LNP";
};

const initiateLogin = async () => {
    const { challenge, csrfGuard } = await generateAndSavePkcs();

    const aadApplicationId = getLoginApplicationId();
    if (!aadApplicationId) {
        Dialog.showError({
            // This cannot be translated, as the message is shown before translations are loaded
            contentMsg: (window.origin + " is not a supported login url.") as MessageId,
        });
        return;
    }

    const queryBuilder = new URLSearchParams();
    queryBuilder.append("client_id", aadApplicationId);
    queryBuilder.append("response_type", "code");
    queryBuilder.append("redirect_uri", window.origin + LOGIN_SUCCESS_PATH);
    queryBuilder.append("response_mode", "query");
    queryBuilder.append("scope", aadApplicationId + "/.default openid");
    queryBuilder.append("state", csrfGuard);
    queryBuilder.append("code_challenge", challenge);
    queryBuilder.append("code_challenge_method", "S256");
    const query = queryBuilder.toString();

    const aadLoginUrl = AAD_LOGIN_URL + "?" + query;
    abortAllRequests();
    window.location.replace(aadLoginUrl);
};

const completeLogin = async (code: string, receivedCsrfGuard: string) => {
    const { savedCodeVerifier, savedCsrfGuard } = loadAndClearPkcs();

    if (!receivedCsrfGuard || !savedCsrfGuard || receivedCsrfGuard !== savedCsrfGuard) {
        throw Error(`CSRF tokens don't match. Saved: ${savedCsrfGuard}, Received: ${receivedCsrfGuard}`);
    }

    if (!savedCodeVerifier) {
        throw Error("Missing saved code verifier.");
    }

    const aadApplicationId = getLoginApplicationId();
    if (!aadApplicationId) {
        Dialog.showError({
            // This cannot be translated, as the message is shown before translations are loaded
            contentMsg: (window.origin + " is not a supported login url.") as MessageId,
        });
        return;
    }

    const loginSuccessUrl = window.origin + LOGIN_SUCCESS_PATH;
    // login is made from entry-ui so module returned is always "LNP" for now
    const module = getCurrentModuleScope();
    const loginResponse = await AuthService.login(code, savedCodeVerifier, loginSuccessUrl, aadApplicationId, module);
    setUserTokenAndExpirationDate(
        loginResponse.access_token,
        loginResponse.expires_at,
        loginResponse.locationsByModule,
        loginResponse.userCountries,
    );
};

export const externalLogout = async () => {
    const queryBuilder = new URLSearchParams();
    queryBuilder.append("post_logout_redirect_uri", window.origin + LOGOUT_SUCCESS_PATH);
    // We need to redirect to the entry-ui here, because we loose access to the module code after logout.
    const query = queryBuilder.toString();

    const aadLogoutUrl = AAD_LOGOUT_URL + "?" + query;
    abortAllRequests();
    window.location.replace(aadLogoutUrl);
};

export const internalLogout = async () => {
    try {
        if (navigator.onLine) await AuthService.logout();
    } catch (e) {
        // Check if the request was aborted due to a redirect / browser unload. If so, we handle it gracefully
        if (globalAbortSignal.aborted && globalAbortSignal.reason === FetchAbortReasons.BROWSER_UNLOAD) {
            return;
        }
        LOG.caught(e, "call to logout failed");
    } finally {
        removeUserToken();
    }
};

const redirectToPreviousLocation = () => {
    const pathBeforeLogin = sessionStorage.getItem(REDIRECT_PATH_STORAGE_ENTRY);
    sessionStorage.removeItem(REDIRECT_PATH_STORAGE_ENTRY);
    // IMPORTANT: If pathBeforeLogin is a proper url (http://nothing.to.see.here) we would redirect to it.
    // -> check that it starts with a "/" to only allow local redirects.
    abortAllRequests();
    if (pathBeforeLogin && pathBeforeLogin.startsWith("/") && !pathBeforeLogin.startsWith(LOGIN_PATH)) {
        window.location.replace(pathBeforeLogin);
    } else {
        window.location.replace("/"); // redirects to Launchpad
    }
};

export enum LoginErrorReason {
    LOGGED_IN = "loggedIn",
    INVALID_STATE = "invalidState",
    NO_POLICIES = "noPolicies",
    INVALID_ACCOUNT = "invalidAccount",
    UNAVAILABLE = "unavailable",
}
export const authGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const userStore = useUserStore();
    switch (to.path) {
        case LOGIN_PATH: {
            const params = new URLSearchParams(window.location.search); // This is already decoding the redirect URL
            const redirectPath = params.get("redirect");
            if (redirectPath) {
                sessionStorage.setItem(REDIRECT_PATH_STORAGE_ENTRY, redirectPath);
            }
            await initiateLogin();
            return;
        }
        case LOGIN_SUCCESS_PATH: {
            if (userStore.isLoggedIn) {
                return createLoginErrorUrl(LoginErrorReason.LOGGED_IN);
            }

            if (!sessionStorage.getItem(CSRF_GUARD_KEY) && !sessionStorage.getItem(CODE_VERIFIER_KEY)) {
                return createLoginErrorUrl(LoginErrorReason.INVALID_STATE);
            }

            const code = to.query.code?.toString();
            const csrfGuardReceived = to.query.state?.toString();
            if (!code || !csrfGuardReceived) {
                LOG.errorMsg("Missing code or CSRF token in URL after redirect.");
                return createLoginErrorUrl();
            }
            try {
                await completeLogin(code, csrfGuardReceived);
            } catch (e) {
                if (e instanceof APIError) {
                    if (e.reason === "ERROR_REASONS_NO_POLICIES") {
                        return createLoginErrorUrl(LoginErrorReason.NO_POLICIES, e.metadata["email"]);
                    }
                    if (e.reason === "ERROR_REASONS_INVALID_ACCOUNT") {
                        return createLoginErrorUrl(LoginErrorReason.INVALID_ACCOUNT, e.metadata["email"]);
                    }
                    if (e.code === 503 || e.code === 504) {
                        return createLoginErrorUrl(LoginErrorReason.UNAVAILABLE);
                    }
                }
                LOG.caught(e, "AUTH_UI_ALERT_WAM: Login failed in UI");
                return createLoginErrorUrl();
            }
            redirectToPreviousLocation();
            return from.fullPath;
        }
        case LOGOUT_PATH: {
            if (!userStore.isLoggedIn) {
                return LOGOUT_SUCCESS_PATH;
            }
            await internalLogout();
            await externalLogout();
            return;
        }
        case LOGOUT_SUCCESS_PATH: {
            return;
        }
        case LOGIN_ERROR_PATH: {
            const errorId = to.query["eri"];
            if (typeof errorId !== "string" || !checkAndClearLoginError(errorId)) {
                redirectToPreviousLocation();
                return from.fullPath;
            }
            return;
        }
    }

    if (!userStore.isLoggedIn) {
        const currentModule = CurrentModule.get();
        const redirectPath = currentModule
            ? currentModule.url.replace(/\/$/, "") + to.fullPath
            : location.pathname.startsWith("/module/launchpad")
              ? "/module/launchpad" + to.fullPath
              : "/module/launchpad/";
        sessionStorage.setItem(REDIRECT_PATH_STORAGE_ENTRY, redirectPath);
        abortAllRequests();
        window.location.replace(LOGIN_PATH);
        return;
    }
};

const createLoginErrorUrl = (reason?: LoginErrorReason, email?: string): string => {
    const queryBuilder = new URLSearchParams();
    queryBuilder.append("eri", generateAndSaveLoginError());
    if (reason) {
        queryBuilder.append("reason", reason);
    }
    if (email) {
        queryBuilder.append("email", email);
    }
    return LOGIN_ERROR_PATH + "?" + queryBuilder.toString();
};

const ERROR_ID_KEY = "loginErrorId";
const ERROR_ID_LENGTH = 8;

const generateAndSaveLoginError = (): string => {
    const errorId = encodeBase64ForUrl(
        [...crypto.getRandomValues(new Uint8Array(ERROR_ID_LENGTH))].map((byte) => String.fromCharCode(byte)).join(""),
    ).substring(0, ERROR_ID_LENGTH);
    sessionStorage.setItem(ERROR_ID_KEY, errorId);
    return errorId;
};

const checkAndClearLoginError = (id: string): boolean => {
    const errorId = sessionStorage.getItem(ERROR_ID_KEY);
    if (errorId === id) {
        sessionStorage.removeItem(ERROR_ID_KEY);
        return true;
    }
    return false;
};

// Pkce is an extension to OAuth 2.0 to prevent CSRF and authorization code injection attacks. https://oauth.net/2/pkce/
// PKCE uses two codes and how they are generated is describe here: https://www.rfc-editor.org/rfc/rfc7636#section-4.1
// code_verifier = high-entropy cryptographic random STRING
// code_challenge = BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))
//
// When redirecting to the identity provider(Microsoft) we provide a code challenge
// that is basically sha256 of a radom strign that we defined and stored
// Later exchanging the one time token for the access token we need to provide the
// code_verifier, in order to the identity provider(Microsoft) check

const CODE_VERIFIER_KEY = "codeVerifier";
const CSRF_GUARD_KEY = "csrfGuard";
const CODE_VERIFIER_LENGTH = 64;

export const encodeBase64ForUrl = (string: string) =>
    window.btoa(string).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

const generateAndSavePkcs = async (): Promise<{ verifier: string; challenge: string; csrfGuard: string }> => {
    const verifier = encodeBase64ForUrl(
        [...crypto.getRandomValues(new Uint8Array(CODE_VERIFIER_LENGTH))]
            .map((byte) => String.fromCharCode(byte))
            .join(""),
    ).substring(0, CODE_VERIFIER_LENGTH);

    const verifierBytes = new Uint8Array([...verifier].map((char) => char.charCodeAt(0)));

    const digest = await crypto.subtle.digest("SHA-256", verifierBytes);

    const challenge = encodeBase64ForUrl([...new Uint8Array(digest)].map((byte) => String.fromCharCode(byte)).join(""));

    const csrfGuard = encodeBase64ForUrl(
        [...crypto.getRandomValues(new Uint8Array(CODE_VERIFIER_LENGTH))]
            .map((byte) => String.fromCharCode(byte))
            .join("") + `_${Date.now()}`,
    );

    sessionStorage.setItem(CODE_VERIFIER_KEY, verifier);
    sessionStorage.setItem(CSRF_GUARD_KEY, csrfGuard);
    DatadogActions.track("set-csrf-guard", { value: csrfGuard });

    return {
        verifier,
        challenge,
        csrfGuard,
    };
};

const loadAndClearPkcs = (): { savedCodeVerifier: string | null; savedCsrfGuard: string | null } => {
    const savedValues = {
        savedCodeVerifier: sessionStorage.getItem(CODE_VERIFIER_KEY),
        savedCsrfGuard: sessionStorage.getItem(CSRF_GUARD_KEY),
    };

    sessionStorage.removeItem(CODE_VERIFIER_KEY);
    sessionStorage.removeItem(CSRF_GUARD_KEY);

    DatadogActions.track("remove-csrf-guard", { value: savedValues.savedCsrfGuard });

    return savedValues;
};
