import { computed, reactive, type Ref, ref, watch } from "vue";
import { type LocationQuery, useRouter } from "vue-router";
import { __ } from "@ui/components";
import { z } from "zod";
import { Dialog } from "./Dialog";

const DIRTY_GUARD_LOCAL_STORAGE_KEY = "wawi-dirty-tabs";

// We don't want the localStorage key to get too large. 100 is a number that should never be reached (we don't expect more than 5 dirty states per tab, and
// this would lead to 20 dirty tabs), but we introduce it for safety reasons.
const MAX_DIRTY_GUARD_LOCAL_STORAGE_LENGTH = 100;

const usedIds: Array<string> = [];

type ConfigDirtyGuard = Partial<{
    // this method will be executed before the dirty check would be done in order to possibly
    // prevent showing a dialog at all and e.g. automatically storing all relevant data without user consent
    onBeforeOpenDialog: () => Promise<void>;
    // regular confirm:
    // will be triggered when the user clicks on "proceed" (secondary)
    // tertiary confirm:
    // will be triggered when the user clicks on "save" (primary) or "discard" (secondary)
    onBeforeLeave: () => Promise<void>;
    // regular confirm:
    // will be triggered when the user clicks on "cancel" (primary)
    // tertiary confirm:
    // will be triggered when the user clicks on "cancel" (secondary)
    onStay: () => Promise<void>;
    // allows to use a tertiary dialog to store current data before proceeding
    // will be triggered when the user clicks on "save" (primary)
    // returning "{ hasError: true }" will indicate that an error happened and will prevent the dialog from closing
    onSave: () => Promise<void | { hasError: true }>;
    // contains a list of query params that would cause loss of dirty data when being updated
    protectedQueryParams: string[];
}>;

type GuardedProp = {
    isDirty?: boolean | undefined;
    config: ConfigDirtyGuard;
};

// we never write "undefined" into the record and "delete" each entry when clearing it
// iterating over these values, we don't want to check for "undefined" values. Hence, we will not declare the
// record with "| undefined".
type DirtyGuardStore = Record<string, GuardedProp>;

const store = reactive<DirtyGuardStore>({});

// exposed for testing
export const _private = { store };

const useDirtyGuardStore = (): Readonly<DirtyGuardStore> => store;

const manualTrigger = ref(() => Promise.resolve(true));

export type DirtyGuardController = {
    // this function allows to trigger the dialog on other actions than navigations that would lead to user data loss
    // if the returned promise resolves to false, the user has chosen to not proceed and has a dirty state
    askToProceed: () => Promise<boolean>;
};

const DirtyGuardStorageSchema = z.array(z.string());

const getDirtyGuardStorage = (): Array<string> => {
    try {
        return DirtyGuardStorageSchema.parse(JSON.parse(localStorage.getItem(DIRTY_GUARD_LOCAL_STORAGE_KEY) ?? "[]"));
    } catch {
        return [];
    }
};

const setDirtyGuardStorage = (ids: Array<string>) => {
    if (ids.length > MAX_DIRTY_GUARD_LOCAL_STORAGE_LENGTH) {
        // We remove the oldest entry
        ids.shift();
    }
    localStorage.setItem(DIRTY_GUARD_LOCAL_STORAGE_KEY, JSON.stringify(ids));
};

const use = (dirty: Ref<boolean>, config: ConfigDirtyGuard = {}): DirtyGuardController => {
    const id = crypto.randomUUID();
    usedIds.push(id);
    watch(
        dirty,
        (nextDirty, _, onCleanup) => {
            store[id] = {
                isDirty: nextDirty,
                config,
            };

            if (nextDirty) {
                // It's safe to assume there will be no concurrency issues for multiple writes at the same time, as we expect one user per machine and the time
                // they'll take to change page and do something that triggers another dirty state is much longer than the time needed to update the localStorage
                const oldGlobalDirty = getDirtyGuardStorage();
                oldGlobalDirty.push(id); // The only relevant information for us is if there's a dirty page somewhere or not, so we don't care about isDirty (it's always true) and config
                setDirtyGuardStorage(oldGlobalDirty);
            }

            onCleanup(() => {
                cleanupDirtyState([id]);
            });
        },
        { immediate: true },
    );
    return { askToProceed: () => manualTrigger.value() };
};

const cleanupDirtyState = (ids: string[]) => {
    ids.forEach((id) => {
        delete store[id];
    });
    usedIds.filter((usedId) => !ids.includes(usedId));
    const oldGlobalDirty = getDirtyGuardStorage();
    setDirtyGuardStorage(oldGlobalDirty.filter((value) => !ids.includes(value)));
};

const isLogoutConfirmed = async () => {
    const localStorageData = getDirtyGuardStorage();
    if (localStorageData.length) {
        return new Promise<boolean>((r) => {
            Dialog.confirm({
                headerMsg: __("ui.libs.dirty-guard.title"),
                contentMsg: __("ui.libs.dirty-guard.logout"),
                confirmMsg: __("ui.libs.dirty-guard.discard"),
                onConfirm: () => {
                    localStorage.removeItem(DIRTY_GUARD_LOCAL_STORAGE_KEY);
                    r(true);
                },
                cancelMsg: __("ui.libs.dirty-guard.continue-editing"),
                onCancel: () => r(false),
            });
        });
    } else {
        return true;
    }
};

export const useDirtyGuardActivation = () => {
    const dirt = computed<GuardedProp["config"][]>(() =>
        Object.values(store)
            .map((v) => v.isDirty && v.config)
            .filter(Boolean),
    );
    const isDirty = computed(() => !!dirt.value.length);
    const store = useDirtyGuardStore();

    const onStay = async (): Promise<void> => {
        const onStays = dirt.value.map(({ onStay }) => onStay).filter(Boolean);
        await Promise.all(onStays.map((cb) => cb()));
    };

    const onBeforeOpenDialog = async (): Promise<void> => {
        const onBeforeOpenDialogs = dirt.value.map(({ onBeforeOpenDialog }) => onBeforeOpenDialog).filter(Boolean);
        await Promise.all(onBeforeOpenDialogs.map((cb) => cb()));
    };

    const onBeforeLeave = async (): Promise<void> => {
        const onBeforeLeaves = dirt.value.map(({ onBeforeLeave }) => onBeforeLeave).filter(Boolean);
        await Promise.all(onBeforeLeaves.map((cb) => cb()));
    };

    const onSave = async (): Promise<boolean> => {
        const onSaves = dirt.value.map(({ onSave }) => onSave).filter(Boolean);
        return (await Promise.all(onSaves.map((cb) => cb()))).some(Boolean);
    };

    const hasNoProtectedQueryChanges = (from: LocationQuery, to: LocationQuery): boolean => {
        for (const d of dirt.value) {
            if (d.protectedQueryParams?.some((p) => from[p] !== to[p])) return false;
        }
        return true;
    };

    const onLeave = computed<() => Promise<boolean>>(() => async () => {
        await onBeforeOpenDialog();
        // if the above handlers modified the dirty state after being executed, the
        // dialog is not needed any more
        if (!isDirty.value) return true;
        // if at least one offers an onSave option, we use the tertiary dialog
        const isOfferingSave = dirt.value.find(({ onSave }) => onSave);

        if (isOfferingSave) {
            return new Promise<boolean>((r) => {
                Dialog.confirm({
                    headerMsg: __("ui.libs.dirty-guard.title"),
                    contentMsg: __("ui.libs.dirty-guard.offer-save.content"),
                    confirmMsg: __("ui.libs.dirty-guard.offer-save.save"),
                    onConfirm: async () => {
                        await onBeforeLeave();
                        const hasError = await onSave();
                        if (hasError) throw Dialog.KEEP_OPEN_ERR;
                    },
                    rejectMsg: __("ui.libs.dirty-guard.discard"),
                    onReject: async () => {
                        await onBeforeLeave();
                    },
                    onUnmounted: (actionType) => {
                        r(actionType === "confirm" || actionType === "reject");
                    },
                    cancelMsg: __("ui.libs.dirty-guard.continue-editing"),
                    onCancel: async () => {
                        await onStay();
                    },
                });
            });
        }

        return new Promise<boolean>((r) => {
            Dialog.confirm({
                headerMsg: __("ui.libs.dirty-guard.title"),
                contentMsg: __("ui.libs.dirty-guard.question"),
                confirmMsg: __("ui.libs.dirty-guard.continue-editing"),
                onConfirm: async () => {
                    await onStay();
                },
                cancelMsg: __("ui.libs.dirty-guard.discard"),
                onCancel: async () => {
                    await onBeforeLeave();
                },
                onUnmounted: (actionType) => {
                    r(actionType === "cancel");
                },
            });
        });
    });
    manualTrigger.value = async () => {
        if (isDirty.value) return onLeave.value();
        return true;
    };

    const router = useRouter();
    // we don't use here "onBeforeRouteLeave" as this can only be invoked inside of <router-view> elements
    router.beforeEach((to, from) => {
        // we only prevent changes to the path. Not the query. If it is necessary to prevent query updates from happening
        // because these could result into losing made changes, it needs to be prevented manually.
        if (!isDirty.value) return true;
        if (to.path === from.path && hasNoProtectedQueryChanges(from.query, to.query)) return true;
        return onLeave.value();
    });

    // we disable the unload behavior for local development because otherwise Vite hot reloads get blocked
    // as long as any form is dirty. This functionality isn't that useful for local development anyway
    // In case you want to test the behavior just comment out the check
    if (import.meta.env.PROD) {
        addEventListener("beforeunload", (event) => {
            // We can't add a check for the localstorage, as we can't know the destination URL (i.e., if it's a refresh or a logout)
            // Reference: https://stackoverflow.com/questions/1686687/how-can-i-get-the-destination-url-for-the-onbeforeunload-event
            if (isDirty.value) {
                // The returned message is not used anyway. The Browser will display its own default message.
                event.returnValue = " "; // should not be empty string
                event.preventDefault(); // required by some browsers
            }
        });
    }

    addEventListener("pagehide", () => cleanupDirtyState(usedIds)); // It clears the localstorage for all our ids if we force the refresh

    return isDirty;
};

export const DirtyGuard = {
    use,
    isLogoutConfirmed,
};
