<script setup lang="ts">
import { computed, CSSProperties, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from "vue";
import { doOnlyOnStage } from "../../utils/stage";
import {
    calculatePopoverCoords,
    layoutObserverMap,
    PopoverAlignment,
    PopoverOpeningDirection,
    PopoverOpenMechanism,
    PopoverPosition,
    PopoverPositionProps,
} from "./WuxPopover.core";

const props = withDefaults(
    defineProps<{
        /**
         * Horizontal alignment of the popover
         */
        alignment?: PopoverAlignment;
        /**
         * The preferred vertical orientation (by default, it's auto)
         *
         * - `top/bottom`: the popover is fixed at top/bottom
         *
         * - `preferTop/preferBottom`: we switch to bottom/top only if we don't fit anymore and the other direction has more available space (but we return
         * to the original preference as soon as possible)
         *
         * - `auto`: we don't have a real preference. After we decide the initial direction, we move to `lazyTop/lazyBottom`, in which we keep the original
         * orientation until when we don't fit anymore (and the other direction has more available space). The difference with `preferTop/preferBottom`
         * is that we stay there until we don't fit anymore (so, we don't change as soon as possible)
         */
        position?: PopoverPositionProps;
        /**
         * If passed, the opening logic will rely only on this props (so, it must be handled by the external component)
         */
        isOpen?: boolean;
        /**
         * When the popover should appear/disappear
         */
        openMechanism?: PopoverOpenMechanism;
        /**
         * A vertical offset
         */
        offset?: number;
        /**
         * The selector of the popover target. In this way, the popover can be attached also to nested elements not accessible
         * inside the component file
         */
        targetSelector?: string;
    }>(),
    {
        alignment: "left",
        position: "auto",
        openMechanism: "hover",
        // It's needed for this reason: https://github.com/vuejs/vue/issues/4792
        // Otherwise, undefined will be casted to false, and we can't distinguish when isOpen is passed and when not
        isOpen: undefined,
    },
);

const emit = defineEmits<{
    "update:isOpen": [value: boolean];
}>();

const popover = useTemplateRef("popover");
const popoverRect = ref<DOMRectReadOnly>();
const popoverTarget = ref<HTMLElement | null>();
const openingDirection = ref<PopoverOpeningDirection>();

const internalIsOpen = ref(false);
const isPopoverOpen = computed(() => props.isOpen ?? internalIsOpen.value);

const handleResize: ResizeObserverCallback = ([resize]) => (popoverRect.value = resize.target.getBoundingClientRect());
const popoverResizeObserver = new ResizeObserver(handleResize);

const updateTargetPosition = () => {
    if (!isPopoverOpen.value || !popoverTarget.value || !popoverRect.value) {
        actualStyle.value = { opacity: 0 };
        return;
    }
    const {
        position,
        coords,
        openingDirection: newOpeningDirection,
    } = calculatePopoverCoords(
        actualAlignment.value,
        popoverTarget.value.getBoundingClientRect(),
        popoverRect.value,
        props.offset,
        actualPosition.value,
    );
    // Delay setting the style for a frame so that the `ResizeObserver` doesn't think it's stuck in a loop
    setTimeout(() => {
        actualStyle.value = coords ?? { opacity: 0 };
        if (position) actualPosition.value = position;
        openingDirection.value = newOpeningDirection;
    }, 0);
};
const targetResizeObserver = new ResizeObserver(() => updateTargetPosition());

const actualStyle = ref<CSSProperties>({ opacity: 0 });
const actualPosition = ref<PopoverPosition>(props.position);
const actualAlignment = computed(() => props.alignment);

const toggle = () =>
    props.isOpen !== undefined ? emit("update:isOpen", !props.isOpen) : (internalIsOpen.value = !internalIsOpen.value);
const show = () => {
    internalIsOpen.value = true;
    emit("update:isOpen", true);
};
const close = () => {
    internalIsOpen.value = false;
    emit("update:isOpen", false);
};

const updateTarget = () => {
    popoverTarget.value?.removeEventListener("click", toggle);
    popoverTarget.value?.removeEventListener("mouseenter", show);
    popoverTarget.value?.removeEventListener("mouseleave", close);
    if (popoverTarget.value) {
        layoutObserverMap.delete(popoverTarget.value);
        targetResizeObserver.unobserve(popoverTarget.value);
    }

    const rootElement = popover.value?.parentElement;
    if (!rootElement) return; // it should be impossible
    popoverTarget.value = props.targetSelector
        ? (rootElement.querySelector<HTMLElement>(props.targetSelector) ?? rootElement)
        : rootElement;

    if (props.openMechanism === "click") {
        popoverTarget.value?.addEventListener("click", toggle);
    } else if (props.openMechanism === "hover") {
        popoverTarget.value?.addEventListener("mouseenter", show);
        popoverTarget.value?.addEventListener("mouseleave", close);
    }

    doOnlyOnStage("DEV", () => {
        if (props.targetSelector && !rootElement.querySelector<HTMLElement>(props.targetSelector)) {
            // eslint-disable-next-line no-console
            console.error(`The targetSelector ${props.targetSelector} didn't point to any element. Please fix it`);
        }
    });
};

watch(
    () => {
        if (!isPopoverOpen.value || !popoverTarget.value || !popoverRect.value) {
            actualStyle.value = { opacity: 0 };
            return;
        }
        return calculatePopoverCoords(
            actualAlignment.value,
            popoverTarget.value.getBoundingClientRect(),
            popoverRect.value,
            props.offset,
            actualPosition.value,
        );
    },
    // Delay setting the style for a frame so that the `ResizeObserver` doesn't think it's stuck in a loop
    (newValues) =>
        setTimeout(() => {
            actualStyle.value = newValues?.coords ?? { opacity: 0 };
            if (newValues?.position) actualPosition.value = newValues.position;
            openingDirection.value = newValues?.openingDirection;
        }, 0),
    { deep: true },
);

const openPopover = () => popover.value?.showPopover();
const hidePopover = () => popover.value?.hidePopover();

onMounted(() => {
    actualPosition.value = props.position;
    updateTarget();
    if (props.openMechanism === "click") {
        popoverTarget.value?.addEventListener("click", toggle);
    } else if (props.openMechanism === "hover") {
        popoverTarget.value?.addEventListener("mouseenter", show);
        popoverTarget.value?.addEventListener("mouseleave", close);
    }
});

watch(isPopoverOpen, () => {
    try {
        if (isPopoverOpen.value) {
            popoverResizeObserver.observe(popover.value!);
            openPopover();
        } else {
            hidePopover();
            popoverResizeObserver.unobserve(popover.value!);
        }
    } catch (e) {
        /* empty */
    }
});

watch(
    () => props.openMechanism,
    () => {
        if (props.openMechanism === "click") {
            popoverTarget.value?.addEventListener("click", toggle);
            popoverTarget.value?.removeEventListener("mouseenter", show);
            popoverTarget.value?.removeEventListener("mouseleave", close);
        } else if (props.openMechanism === "hover") {
            popoverTarget.value?.removeEventListener("click", toggle);
            popoverTarget.value?.addEventListener("mouseenter", show);
            popoverTarget.value?.addEventListener("mouseleave", close);
        }
    },
);

watch([popoverTarget, isPopoverOpen], () => {
    if (!popoverTarget.value) {
        return;
    }
    if (isPopoverOpen.value) {
        layoutObserverMap.set(popoverTarget.value, updateTargetPosition);
        targetResizeObserver.observe(popoverTarget.value);
    } else {
        layoutObserverMap.delete(popoverTarget.value);
        targetResizeObserver.unobserve(popoverTarget.value);
    }
});

onBeforeUnmount(() => {
    popoverTarget.value?.removeEventListener("click", toggle);
    popoverTarget.value?.removeEventListener("mouseenter", show);
    popoverTarget.value?.removeEventListener("mouseleave", close);
    popoverResizeObserver.disconnect();
    if (popoverTarget.value) {
        layoutObserverMap.delete(popoverTarget.value);
        targetResizeObserver.unobserve(popoverTarget.value);
    }
});
</script>

<template>
    <div
        ref="popover"
        class="wux-popover"
        :class="{
            [`wux-popover--${actualPosition}`]: actualPosition,
            [`wux-popover--${actualAlignment}`]: actualAlignment,
            [`wux-popover--${openingDirection}-direction`]: openingDirection,
        }"
        :style="actualStyle"
        :is-open="isPopoverOpen"
        popover="manual"
        @click="(e: MouseEvent) => e.stopPropagation()"
    >
        <slot v-if="isPopoverOpen"></slot>
    </div>
</template>

<style lang="scss">
.wux-popover {
    position: absolute;
    visibility: hidden;
    margin: 0;
    padding: 0;
    border: none;
    pointer-events: none;
    background: transparent;

    &:popover-open {
        visibility: visible;
        pointer-events: auto;
    }
}
</style>
