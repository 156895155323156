<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, unref, useTemplateRef, watch } from "vue";
import { Icons } from "../../icons";
import { Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxTab from "./WuxTab.vue";
import { Tab, WuxTabBarEmits, WuxTabBarProps } from "./WuxTabBar.core";

const emit = defineEmits<WuxTabBarEmits>();

const props = defineProps<WuxTabBarProps>();

const onChange = (id: string) => {
    const elem = tabRefs.value[id];
    elem.$el.scrollIntoView({ block: "end", behavior: "smooth", inline: "center" });
    emit("update:modelValue", id);
};

const slots = defineSlots();

const selectedTab = computed(() => props.tabs.find((tab) => tab.id === props.modelValue)?.id ?? props.tabs[0]?.id);

const tabsContainerRef = useTemplateRef("tabsContainerRef");
const tabRefs = ref<Record<string, InstanceType<typeof WuxTab>>>({});
const isLeftScrollable = ref(false);
const isRightScrollable = ref(false);
const focusedTab = ref(0);
const isFocused = ref(false); // To show the tab outline only when the TabBar is focused
const isMouseClick = ref(false); // We need it as, otherwise, while mouse clicking a tab the outline will be shown

const updateScrollable = () => {
    if (!tabsContainerRef.value || tabsContainerRef.value.scrollWidth <= tabsContainerRef.value.clientWidth) {
        isLeftScrollable.value = false;
        isRightScrollable.value = false;
        return;
    }

    if (tabsContainerRef.value.scrollWidth <= tabsContainerRef.value.clientWidth) return;
    isLeftScrollable.value = tabsContainerRef.value.scrollLeft > 0;
    isRightScrollable.value =
        tabsContainerRef.value.scrollWidth - tabsContainerRef.value.clientWidth - tabsContainerRef.value.scrollLeft >=
        1;
};

const scrollToLeft = () => {
    if (!tabsContainerRef.value) return;
    const remainingScrollAmount = tabsContainerRef.value.scrollLeft;
    const availableWidth = tabsContainerRef.value.clientWidth;
    tabsContainerRef.value.scrollLeft -= Math.min(remainingScrollAmount, availableWidth);
};

const scrollToRight = () => {
    if (!tabsContainerRef.value) return;
    const remainingScrollAmount =
        tabsContainerRef.value.scrollWidth - tabsContainerRef.value.clientWidth - tabsContainerRef.value.scrollLeft;
    const availableWidth = tabsContainerRef.value.clientWidth;
    tabsContainerRef.value.scrollLeft += Math.min(remainingScrollAmount, availableWidth);
};

const onFocus = () => (isFocused.value = true);

const onBlur = () => (isFocused.value = false);

const onMousedown = () => (isMouseClick.value = true);

const onMouseUp = () => (isMouseClick.value = false);

const onClick = (e: MouseEvent, tab: Tab) => {
    tabsContainerRef.value?.blur(); // To avoid that, after the mouse click, the outline is shown even if we didn't use keyboard navigation
    tab.onClick?.(e);
};

const onKeydown = (event: KeyboardEvent) => {
    const focusNext = (e: KeyboardEvent) => {
        e.preventDefault();
        let nextIndex = (focusedTab.value + 1) % props.tabs.length;
        while (props.tabs[nextIndex].isDisabled) {
            nextIndex = (nextIndex + 1) % props.tabs.length;
        }
        focusedTab.value = nextIndex;
    };

    const focusPrevious = (e: KeyboardEvent) => {
        e.preventDefault();
        let previousIndex = focusedTab.value === 0 ? props.tabs.length - 1 : focusedTab.value - 1;
        while (props.tabs[previousIndex].isDisabled) {
            previousIndex = previousIndex === 0 ? props.tabs.length - 1 : previousIndex - 1;
        }
        focusedTab.value = previousIndex;
    };

    const onSelected = (e: KeyboardEvent) => {
        props.tabs[focusedTab.value].onClick?.(e);
        onChange(props.tabs[focusedTab.value].id);
    };

    return ShortcutUtil.getKeyDownHandler(
        [Shortcuts.ArrowRight, focusNext],
        [Shortcuts.ArrowLeft, focusPrevious],
        [Shortcuts.Space, onSelected],
        [Shortcuts.Enter, onSelected],
    )(event);
};

const resizeObserver = new ResizeObserver(() => updateScrollable());

onMounted(() => {
    if (!tabsContainerRef.value) return;
    resizeObserver.observe(tabsContainerRef.value);
    tabsContainerRef.value.addEventListener("scroll", updateScrollable);
});

onBeforeUnmount(() => {
    if (!tabsContainerRef.value) return;
    resizeObserver.unobserve(tabsContainerRef.value);
});

watch(
    () => tabsContainerRef.value,
    () => updateScrollable(),
);

watch([focusedTab, isFocused], () => {
    const id = props.tabs[focusedTab.value].id;
    const elem = tabRefs.value[id];
    if (!elem) return;
    elem.$el.scrollIntoView({ block: "end", behavior: "smooth", inline: "center" });
});
</script>

<template>
    <div class="wux-tab-bar">
        <div class="wux-tab-bar__container">
            <div class="wux-tab-bar__button-wrapper">
                <div
                    class="wux-tab-bar__navigation-bar"
                    ref="tabsContainerRef"
                    tabindex="0"
                    @focus="onFocus"
                    @blur="onBlur"
                    @keydown="onKeydown"
                    @mousedown="onMousedown"
                    @mouseup="onMouseUp"
                >
                    <WuxButton
                        v-if="isLeftScrollable && (!isFocused || isMouseClick)"
                        class="wux-tab-bar__navigation-button wux-tab-bar__navigation-button--start"
                        :icon="Icons.chevron_left_small"
                        :variant="'outline'"
                        @click="scrollToLeft"
                    />
                    <WuxTab
                        :ref="(el: any) => (tabRefs[tab.id] = el)"
                        v-for="(tab, index) in props.tabs"
                        :key="tab.id"
                        :id="tab.id"
                        :disabled="tab.isDisabled"
                        :isFocused="isFocused && !isMouseClick && index === focusedTab"
                        :label="tab.label"
                        :labelMsg="tab.labelMsg"
                        :statusIcon="tab.statusIcon"
                        :isDisabled="tab.isDisabled"
                        :isSelected="selectedTab === tab.id"
                        :onClick="(event) => onClick(event, tab)"
                        @update:modelValue="onChange"
                    />
                    <WuxButton
                        v-if="isRightScrollable && (!isFocused || isMouseClick)"
                        class="wux-tab-bar__navigation-button wux-tab-bar__navigation-button--end"
                        :icon="Icons.chevron_right_small"
                        :variant="'outline'"
                        @click="scrollToRight"
                    />
                </div>
            </div>
            <div class="wux-tab-bar__slot" v-if="!!slots['rightSide']">
                <slot name="rightSide" />
            </div>
        </div>
        <div class="wux-tab-bar__content">
            <!-- TODO(mc): Remove unref in WAU-XCLYVL -->
            <slot :key="selectedTab" :name="unref(selectedTab)" />
        </div>
    </div>
</template>
<style lang="scss">
.wux-tab-bar {
    --wux-tab-bar-content-padding-top: 1rem;
    --wux-tab-bar-content-padding-sides: 3px; // needed to display the shadow of WuxTiles
    .wux-sidebars & {
        // don't leave space for side shadows inside a sidebar
        --wux-tab-bar-content-padding-sides: 0;
    }
    &:has(.wux-tab-bar__content > .wux-tile) {
        // no top padding if first element is a wux-tile
        --wux-tab-bar-content-padding-top: 0;
    }
    &__container {
        display: flex;
        align-items: center;
        gap: 2rem;
        border-bottom: 1px solid var(--wawi-color-neutral-500);
    }
    &__slot {
        flex: 0;
    }
    &__button-wrapper {
        flex: 1;
        display: flex;
        overflow: auto;
        position: relative;
    }
    &__navigation-bar {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        overflow: auto;
        scroll-behavior: smooth;

        -ms-overflow-style: none; // Hide the scrollbar on IE and Edge
        scrollbar-width: none; // Hide the scrollbar on Firefox
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            //Hide the scrollbar on Chrome, Safari, and Opera
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    &__content {
        // make space for the shadow
        padding: var(--wux-tab-bar-content-padding-top) var(--wux-tab-bar-content-padding-sides) 0
            var(--wux-tab-bar-content-padding-sides); // make space for the shadow
        overflow-x: auto;
    }

    &__navigation-button {
        position: absolute;
        z-index: 2;
        min-height: 1rem;
        background-color: var(--wawi-color-neutral-100);
        opacity: 95%;
        &:focus-visible {
            outline-offset: -3px;
            outline-width: 3px;
        }
        &--end {
            right: 0px;
        }
        &:has(.wux-icon) {
            padding: 0;
        }
    }
}
</style>
