import { z } from "zod";
import { _getTimeoutController, FetchAbortReasons } from "./abortController";
import { Endpoint } from "./runtime";

export enum ClientSideErrorCodes {
    UNEXPECTED = -1, // something unexpected happened
    PARSING = -2, // if the "zod" parser has thrown an error
    TIMEOUT = -3, // if the request was pending for more than MAX_TIMEOUT
    ABORT = -4, // e.g. if resource was not required anymore
    OFFLINE = -5, // e.g. if the user is offline
}

const MAX_TIMEOUT = 60_000; // 1 min

type ErrorDetail = {
    "@type"?: any;
};

type ErrorInfo = ErrorDetail & {
    "@type": `type.googleapis.com/google.rpc.ErrorInfo`;
    reason: string;
    domain: string;
    metadata: Record<string, string | undefined>;
};

export type FieldViolation = {
    field: string;
    description: string;
};

export type PreconditionViolation = {
    type: string;
    subject: string;
    description: string;
};

type BadRequest = ErrorDetail & {
    "@type": `type.googleapis.com/google.rpc.BadRequest`;
    fieldViolations?: FieldViolation[];
};

type PreconditionFailure = ErrorDetail & {
    "@type": `type.googleapis.com/google.rpc.PreconditionFailure`;
    violations?: PreconditionViolation[];
};

export class APIError extends Error {
    code: number;
    statusText: string;
    reason?: string;
    metadata: Record<string, string | undefined>;
    fieldViolations: FieldViolation[];
    preconditionViolations: PreconditionViolation[];

    constructor(code: number, statusText: string, json: { message?: string; details?: ErrorDetail[] } = {}) {
        // we favor json.message over statusText if provided
        const text = json.message || statusText;

        const errorInfo = json.details?.find(
            (element) => element["@type"] === "type.googleapis.com/google.rpc.ErrorInfo",
        ) as ErrorInfo | undefined;

        const badRequest = json.details?.find(
            (element) => element["@type"] === "type.googleapis.com/google.rpc.BadRequest",
        ) as BadRequest | undefined;

        const preconditionFailure = json.details?.find(
            (element) => element["@type"] === "type.googleapis.com/google.rpc.PreconditionFailure",
        ) as PreconditionFailure | undefined;

        // fallback to undefined if reason is empty
        const reason = errorInfo?.reason || undefined;
        super(`APIError (${code}): ${text}${reason ? ` (reason: ${reason})` : ""}`);
        Error.captureStackTrace?.(this, APIError);

        this.name = this.constructor.name;
        this.code = code;
        this.statusText = text;
        this.reason = reason;
        this.metadata = errorInfo?.metadata || {};
        this.fieldViolations = badRequest?.fieldViolations || [];
        this.preconditionViolations = preconditionFailure?.violations || [];
    }
}

const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

const encodeQueryParam = (name: string, value: unknown) =>
    `${encodeURIComponent(camelToSnakeCase(name))}=${encodeURIComponent(String(value))}`;

const flattenParams = (params: Record<string, unknown>, prefix = ""): [key: string, value: unknown][] =>
    Object.entries(params)
        .filter(([, value]) => value !== undefined && value !== null)
        .flatMap<[string, unknown]>(([key, value]) =>
            Array.isArray(value)
                ? value.map<[string, unknown]>((item) => [prefix + key, item])
                : typeof value === "object"
                  ? flattenParams(value as Record<string, unknown>, prefix + key + ".")
                  : [[prefix + key, value]],
        );

const encodeQueryParams = (params: Record<string, unknown>): string =>
    flattenParams(params)
        .map(([key, value]) => encodeQueryParam(key, value))
        .join("&");

const convertToAPIError = (err: Error | APIError) => {
    if (err instanceof APIError) throw err;
    if (err instanceof z.ZodError) throw new APIError(ClientSideErrorCodes.PARSING, JSON.stringify(err.issues));
    throw new APIError(ClientSideErrorCodes.UNEXPECTED, err.message);
};

type Config = {
    onUnauthorized?: () => Promise<void>;
    getAdditionalHeaders?: () => HeadersInit;
    onStartLoading?: (noLoadingIndicator?: boolean) => Promise<void>;
    onEndLoading?: (noLoadingIndicator?: boolean) => void;
    mockedFetch?: (params: {
        endpoint: Endpoint<any, any, any, any>;
        pathParams?: any;
        queryParams?: any;
        body?: any;
    }) => any;
};

export const _config: Config = {};

const configureWaWiClient = ({
    onUnauthorized,
    getAdditionalHeaders,
    onStartLoading,
    onEndLoading,
    mockedFetch,
}: Partial<Config>) => {
    if (onUnauthorized) _config.onUnauthorized = onUnauthorized;
    if (getAdditionalHeaders) _config.getAdditionalHeaders = getAdditionalHeaders;
    if (onStartLoading) _config.onStartLoading = onStartLoading;
    if (onEndLoading) _config.onEndLoading = onEndLoading;
    if (mockedFetch) _config.mockedFetch = mockedFetch;
};

type WellKnownHeaders = {
    "wawi-glb-country-scope"?: string;
};

const fetchParsedResponse = async <RESPONSE, BODY = void, PATH_PARAMS = void, QUERY_PARAMS = void>({
    endpoint,
    queryParams,
    body,
    pathParams,
    signal,
    timeout = MAX_TIMEOUT,
    noLoadingIndicator,
    headers: providedHeaders,
}: {
    endpoint: Endpoint<RESPONSE, BODY, PATH_PARAMS, QUERY_PARAMS>;
    queryParams?: QUERY_PARAMS;
    body?: BODY;
    pathParams?: PATH_PARAMS;
    signal?: AbortSignal;
    timeout?: number;
    noLoadingIndicator?: boolean;
    headers?: WellKnownHeaders;
}): Promise<RESPONSE> => {
    if (_config.mockedFetch) {
        return _config.mockedFetch({ endpoint, pathParams, queryParams, body });
    }
    if (!window.navigator.onLine) {
        throw new APIError(ClientSideErrorCodes.OFFLINE, FetchAbortReasons.OFFLINE);
    }
    const ctrl = _getTimeoutController(timeout, signal);
    try {
        await _config.onStartLoading?.(noLoadingIndicator);
        const path = `/api${typeof endpoint.path === "function" ? endpoint.path(pathParams as PATH_PARAMS) : endpoint.path}`;
        const query = queryParams && encodeQueryParams(queryParams);
        const url = path + (query ? "?" + query : "");

        const additionalHeaders = _config.getAdditionalHeaders?.();
        const headers = {
            "Content-Type": "application/json",
            ...additionalHeaders,
            ...providedHeaders,
        };

        return await fetch(url, {
            method: endpoint.method,
            body: JSON.stringify(
                body,
                (_key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
            ),
            headers,
            signal: ctrl.signal,
        }).then(async (response) => {
            if (response.status === 401) {
                await _config.onUnauthorized?.();
            }
            if (!response.ok) {
                throw new APIError(response.status, response.statusText, await response.json().catch(() => undefined));
            }
            return endpoint.parser(await response.json());
        });
    } catch (e) {
        if (ctrl.signal.aborted) {
            throw new APIError(
                ctrl.signal.reason === FetchAbortReasons.TIMEOUT
                    ? ClientSideErrorCodes.TIMEOUT
                    : ClientSideErrorCodes.ABORT,
                ctrl.signal.reason,
            );
        }
        throw convertToAPIError(e as Error);
    } finally {
        _config.onEndLoading?.(noLoadingIndicator);
        ctrl.clearTimeout();
    }
};

export const Fetch = { configureWaWiClient, fetchParsedResponse };
